import Vue from 'vue';
import VueRouter from 'vue-router';
import CreatePayment from './views/CreatePayment.vue';
import PaymentResult from './views/PaymentResult.vue';
import NotCorrectPayLinkKey from './views/NotCorrectPayLinkKey.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'notCorrectPayLinkKey',
    component: NotCorrectPayLinkKey,
  },
  {
    path: '/key/:payLinkKey',
    name: 'createPayment',
    component: CreatePayment,
  },
  {
    path: '/key/:payLinkKey/:result',
    name: 'paymentResult',
    component: PaymentResult,
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
