import axios from 'axios';

axios.defaults.headers.common.Accept = 'application/json';

const ApiInstance = axios.create({
  baseURL: '/api/',
  timeout: 30000,
  maxBodyLength: Infinity,
  maxContentLength: Infinity,
});

export default ApiInstance;
