<template>
  <div>
    <v-alert outlined width="500" type="warning" class="ma-auto">
      Пожалуйста, обратитесь в свою школу для получения актуальной ссылки на оплату.
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'NotCorrectPayLinkKey',
  components: {},
  data: () => ({}),
};
</script>

<style scoped>

</style>
