<template>
  <div>
    <v-container>
      <v-layout column justify-center align-center>
        <v-flex md4 xs12 sm6 class="text-center mb-2" v-if="uiSettings.logo">
          <a v-bind:href="uiSettings.contacts.site" target="_blank">
            <img :src="uiSettings.logo" class="logo">
          </a>
          <p v-if="uiSettings.contacts.name">
            {{ uiSettings.contacts.name }}
          </p>
        </v-flex>
        <v-card v-if="!isLoadingUserData && user" width="500">
          <v-card-text class="black--text show-scrollbar pt-4 pb-2 pb-sm-4">
            <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              absolute
              bottom
              color="primary"
            ></v-progress-linear>

            <v-radio-group
              @change="changePayFor"
              hide-details
              class="mt-0"
              v-model="payFor">
              <v-radio
                v-if="selectInvoices.length
            && (!preassignedPayload.payFor || preassignedPayload.payFor === 'invoice')"
                :label="'Оплатить долг'"
                :value="'invoice'"
              ></v-radio>
              <v-radio
                v-if="selectJoins.length
            && (!preassignedPayload.payFor || preassignedPayload.payFor === 'join')"
                :label="'Купить абонемент/оплатить обучение'"
                :value="'join'"
              ></v-radio>
              <v-radio
                v-if="uiSettings.onlinePayment.depositOnBalans
            && (!preassignedPayload.payFor || preassignedPayload.payFor === 'balans')"
                :label="'Внести сумму на баланс'"
                :value="'balans'"
              ></v-radio>
            </v-radio-group>

            <div class="pt-5" v-if="payFor==='invoice' && selectInvoices.length">
              <h2 v-if="currentDebt === totalDebt && !preassignedPayload.invoiceId" class="body-1">
                Долг: <span class="red--text">{{ totalDebt }} </span> {{ currency }}
              </h2>
              <h2 v-else-if="!preassignedPayload.invoiceId" class="body-1">
                Долг:
                <span class="red--text">{{ currentDebt }}</span>
                из
                <span class="red--text">{{ totalDebt }}</span>
                {{ currency }}
              </h2>
              <h2 v-else class="body-1">
                Счет для оплаты
              </h2>

              <v-radio-group @change="invoiceChange"
                             required
                             v-model="payload.invoiceId"
                             hide-details>
                <v-radio
                  v-for="invoice in selectInvoices"
                  :key="invoice.id"
                  :value="invoice.id"
                >
                  <template slot="label">
                    <div class="d-block">
                      <div>{{ invoice.displayName }}</div>
                      <div class="caption grey--text">
                        <span :class="invoice.payUntilClass">
                          Оплатить до {{ invoice.payUntil }}
                        </span>
                        Счет №{{ invoice.id }}
                      </div>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>

              <div class="grey lighten-3 pt-3 pl-2 mt-5 mb-5 pb-3">
                <h2 class="body-1">
                  Баланс:
                  <span class="primary--text">{{ user.availableBalance }}</span>
                  {{ currency }}
                </h2>

                <v-checkbox
                  v-if="user.availableBalance"
                  v-model="payload.debitFromBalans"
                  :label="'Списать средства с баланса'"
                  hide-details>
                </v-checkbox>
              </div>

              <h2 class="body-1">
                Итого к оплате:
                <span class="primary--text">{{ totalSum }}</span>
                {{ currency }}
              </h2>
            </div>

            <div class="pt-5" v-if="payFor==='join' && selectJoins.length">
              <h2 class="body-1">
                Ваши направления/группы
              </h2>

              <v-radio-group
                @change="joinChange"
                required
                v-model="payload.joinId">
                <v-radio
                  v-for="join in selectJoins"
                  :key="join.id"
                  :label="join.displayName"
                  :value="join.id"
                ></v-radio>
              </v-radio-group>

              <h2 v-if="selectSubscriptionTypes.length" class="body-1">
                Выберите абонемент
              </h2>

              <v-select
                v-if="selectSubscriptionTypes.length"
                :items="selectSubscriptionTypes"
                v-model="payload.subscriptionId"
                item-text="displayName"
                item-value="id"
                label="Абонемент"
                @change="subsChange"
              ></v-select>

              <h2 v-if="isShowSubscriptionBeginDateSelect" class="body-1">
                Начало действия абонемента
              </h2>

              <v-radio-group
                v-if="isShowSubscriptionBeginDateSelect"
                required v-model="payload.subscriptionStartDate">
                <v-radio
                  v-if="!preassignedPayload.subscriptionStartDate
              || preassignedPayload.subscriptionStartDate === 'fromNextMonth'"
                  :label="'Со следующего месяца'"
                  :value="'fromNextMonth'"
                ></v-radio>
                <v-radio
                  v-if="!preassignedPayload.subscriptionStartDate
              || preassignedPayload.subscriptionStartDate === 'fromThisMonth'"
                  :label="'С начала текущего месяца'"
                  :value="'fromThisMonth'"
                ></v-radio>
                <v-radio
                  v-if="!preassignedPayload.subscriptionStartDate
              || preassignedPayload.subscriptionStartDate === 'fromNow'"
                  :label="'С текущей даты'"
                  :value="'fromNow'"
                ></v-radio>
              </v-radio-group>

              <h2 class="body-1 mb-5" v-if="joinDiscount">
                Скидка:
                <span class="primary--text">{{ joinDiscount }}</span>
                {{ currency }}
              </h2>

              <v-text-field
                v-if="isShowPriceInput && subPriceLimits.max
            && subPriceLimits.min && !preassignedPayload.summa"
                v-model="payload.summa"
                class="mb-2"
                :label="`Сумма оплаты
                (от ${subPriceLimits.min} до ${subPriceLimits.max} ${currency})`"
                :placeholder="String(subPriceLimits.max)"
                type="number"
                min="1">
              </v-text-field>

              <div class="grey lighten-3 pt-3 pl-2 mb-5 pb-3">
                <h2 class="body-1">
                  Баланс:
                  <span class="primary--text">{{ user.availableBalance }}</span>
                  {{ currency }}
                </h2>

                <v-checkbox
                  v-if="user.availableBalance"
                  v-model="payload.debitFromBalans"
                  :label="'Списать средства с баланса'"
                  hide-details>
                </v-checkbox>
              </div>

              <h2 class="body-1">
                Итого к оплате:
                <span class="primary--text">{{ totalSum }}</span>
                {{ currency }}
              </h2>
            </div>

            <div class="pt-3"
                 v-bind:class="{'pb-2': onlinePaymentIntegrationsSelect
               && onlinePaymentIntegrationsSelect.length > 1 }"
                 v-if="payFor==='balans' && uiSettings.onlinePayment.depositOnBalans">
              <v-text-field
                v-model="payload.summa"
                label="Сумма"
                type="number"
                hide-details
                min="1">
              </v-text-field>
            </div>

            <v-select
              class="mt-4"
              v-if="onlinePaymentIntegrationsSelect && onlinePaymentIntegrationsSelect.length > 1"
              :items="onlinePaymentIntegrationsSelect"
              v-model="integrationId"
              item-text="name"
              item-value="id"
              hide-details
              label="Платежная система"
            ></v-select>

            <v-alert outlined
                     type="warning"
                     v-if="onlinePaymentIntegrationsSelect
                     && !onlinePaymentIntegrationsSelect.length"
                     class="body-2 mt-4 mb-0">
              Нет подходящей платежной системы. Пожалуйста, обратитесь в вашу школу.
            </v-alert>
          </v-card-text>
          <v-card-actions class="align-center align-center justify-center flex-wrap">
            <v-btn :disabled="!formValid || loading" @click="createPayment()"
                   text
                   :key="refreshKey"
                   color="accent">
              {{ createPaymentBtnText }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-if="isLoadingUserData" width="500">
          <v-skeleton-loader
            type="
        card-heading,
        list-item-two-line,
        card-heading,
        list-item-three-line,
        list-item-three-line,
        actions"
          ></v-skeleton-loader>
        </v-card>
        <div class="mt-6 contacts text-center">
          <div class="caption" v-if="uiSettings.contacts.phone">
            {{ uiSettings.contacts.phone }}
          </div>
          <div class="caption" v-if="uiSettings.contacts.email">
            <a v-bind:href="'mailto:'+uiSettings.contacts.email">
              {{ uiSettings.contacts.email }}</a>
          </div>
          <div class="caption" v-if="companySite">
            <a v-bind:href="uiSettings.contacts.site" target="_blank">{{ companySite }}</a>
          </div>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { mapGetters, mapMutations } from 'vuex';

import UserData from '@/api/UserData';
import Payment from '@/api/Payment';

export default {
  name: 'CreatePayment',
  components: {},
  data: () => {
    const payFor = 'invoice';
    const payload = {};

    return {
      payFor,
      payload,
      joins: [],
      classes: [],
      invoices: [],
      userSubscriptions: [],
      subscriptionTypes: [],
      preassignedPayload: {},
      onlinePaymentIntegrations: [],
      totalDebt: 0,
      currentDebt: 0,
      refreshKey: 0,
      payLinkKey: null,
      uiSettings: { onlinePayment: {}, contacts: {} },
      loading: false,
      integrationId: null,
      isLoadingUserData: false,
    };
  },
  computed: {
    companySite() {
      if (this.uiSettings.contacts.site) {
        const matches = this.uiSettings.contacts.site.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i);
        return matches && matches[1];
      }
      return false;
    },
    userDiscount() {
      if (!this.user) {
        return 0;
      }

      const discountAttr = this.user.attributes.find((attr) => attr.attributeAlias === 'discount');

      return discountAttr ? discountAttr.value : 0;
    },
    priceForJoin() {
      if (!this.payload.joinId) {
        return 0;
      }

      const join = this.joins.find((j) => j.id === this.payload.joinId);
      if (!join) {
        return 0;
      }

      const cl = this.classes.find((c) => c.id === join.classId);

      if (cl.payType === 'full') {
        return join.price;
      }

      if (this.payload.subscriptionId) {
        const subType = this.subscriptionTypes
          .find((s) => s.id === this.payload.subscriptionId);
        const allowedSubscriptionTypeIds = this.getSubscriptionTypesForClass(cl.id)
          .map((s) => s.id);

        return allowedSubscriptionTypeIds.includes(subType.id) ? subType.price : 0;
      }

      return 0;
    },
    joinDiscount() {
      if (!this.payload.joinId) {
        return 0;
      }

      const join = this.joins.find((j) => j.id === this.payload.joinId);
      if (!join) {
        return 0;
      }

      const cl = this.classes.find((c) => c.id === join.classId);

      if (cl.payType === 'full') {
        return undefined;
      }

      if (this.payload.subscriptionId && cl.useDiscount && this.userDiscount) {
        return Math.round((this.priceForJoin * (this.userDiscount)) / 100);
      }

      return 0;
    },
    subPriceLimits() {
      if (!this.payload.subscriptionId || !this.payload.joinId) {
        return {};
      }

      const join = this.joins.find((j) => j.id === this.payload.joinId);
      if (!join) {
        return {};
      }

      const cl = this.classes.find((c) => c.id === join.classId);
      if (!cl || cl.payType === 'full') {
        return {};
      }

      const subType = this.getSubscriptionTypesForClass(cl.id)
        .find(({ id }) => id === this.payload.subscriptionId);

      if (!subType) {
        return {};
      }

      const result = {
        max: this.priceForJoin - this.joinDiscount,
      };

      const { partialPaymentForNewJoinMin, partialPaymentForNewJoin } = cl.onlinePayment;

      if (partialPaymentForNewJoin) {
        result.min = Math.round((result.max * +partialPaymentForNewJoinMin) / 100);
      } else {
        result.min = result.max;
      }

      return result;
    },
    isShowPriceInput() {
      if (!this.payload.subscriptionId || !this.payload.joinId) {
        return false;
      }

      const join = this.joins.find((j) => j.id === this.payload.joinId);
      if (!join) {
        return false;
      }

      const cl = this.classes.find((c) => c.id === join.classId);
      if (!cl || cl.payType === 'full') {
        return false;
      }

      return cl.onlinePayment.partialPaymentForNewJoin;
    },
    selectInvoices() {
      if (!this.invoices) {
        return [];
      }

      const invoices = this.invoices
        .map((invoice) => {
          invoice.needToPay = invoice.nextSum || invoice.price - invoice.payed;

          if (invoice.nextSum && invoice.nextSum !== invoice.price - invoice.payed) {
            invoice.displayName = `${invoice.needToPay} из ${invoice.price - invoice.payed} ${this.currency}`;
          } else {
            invoice.displayName = `${invoice.needToPay} ${this.currency}`;
          }

          if (invoice.userSubscriptionId) {
            const sub = this.userSubscriptions.find((us) => us.id === invoice.userSubscriptionId);
            if (sub) {
              invoice.displayName += ` - Аб. №${sub.id} ${this.getClassName(sub.mainClassId)}`;
            }
          }
          if (invoice.joinId) {
            const join = this.joins.find((j) => j.id === invoice.joinId);
            if (join) {
              invoice.displayName += ` - ${this.getClassName(join.classId)}`;
            }
          }

          if (invoice.payUntil) {
            const diff = -moment().diff(invoice.payUntil, 'day');
            invoice.payUntilClass = null;
            if (diff < 0) {
              invoice.payUntilClass = 'red--text';
            }
            invoice.payUntil = moment(invoice.payUntil).format('DD/MM/YYYY');
          }

          return invoice;
        })
        .filter(Boolean);

      if (this.preassignedPayload.invoiceId) {
        const invoice = invoices.find((i) => +this.preassignedPayload.invoiceId === i.id);
        if (invoice) {
          return [invoice];
        }
      }

      return invoices;
    },
    selectJoins() {
      if (!this.joins) {
        return [];
      }
      const joins = this.joins
        .map((join) => {
          const cl = this.classes.find((c) => c.id === join.classId);

          if (!cl || !cl.onlinePayment.enabledFromPayLink) {
            return null;
          }

          if (join.invoices.length) {
            return null;
          }

          if (join.status === 'over') {
            return null;
          }

          join.displayName = cl.name;

          if (cl.payType === 'full') {
            join.displayName += ` - ${join.price} ${this.currency}`;
          } else {
            const subscriptionTypes = this.getSubscriptionTypesForClass(cl.id);

            if (!subscriptionTypes.length) {
              return null;
            }
          }

          return join;
        })
        .filter(Boolean)
        .sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1));

      if (this.preassignedPayload.joinId) {
        const join = joins.find((j) => +this.preassignedPayload.joinId === j.id);
        if (join) {
          return [join];
        }
      }

      return joins;
    },
    selectSubscriptionTypes() {
      if (!this.payload.joinId) {
        return [];
      }

      const join = this.joins.find((j) => j.id === this.payload.joinId);
      if (!join) {
        return [];
      }

      const subTypes = this.getSubscriptionTypesForClass(join.classId);

      if (this.preassignedPayload.subscriptionId) {
        const sub = subTypes.find((s) => +this.preassignedPayload.subscriptionId === s.id);
        if (sub) {
          return [sub];
        }
      }

      return subTypes;
    },
    isShowSubscriptionBeginDateSelect() {
      if (!this.payload.joinId || !this.payload.subscriptionId) {
        return false;
      }

      const subscriptionType = this.subscriptionTypes
        .find((s) => s.id === this.payload.subscriptionId);

      return subscriptionType ? subscriptionType.onlinePayment.beginDateSelectEnabled : false;
    },
    totalSum() {
      if (this.payFor === 'invoice' && this.payload.invoiceId) {
        const invoice = this.invoices.find((i) => i.id === this.payload.invoiceId);

        let { needToPay } = invoice;

        if (this.partialPaymentForInvoice && this.payload.summa) {
          needToPay = this.payload.summa;
        }

        if (!this.payload.debitFromBalans) {
          return needToPay;
        }

        return this.user.availableBalance > needToPay ? 0
          : needToPay - this.user.availableBalance;
      }

      if (this.payFor === 'join' && this.payload.joinId) {
        let resultPrice = this.priceForJoin - (this.joinDiscount || 0);

        if (this.payload.summa) {
          resultPrice = +this.payload.summa;
        }

        if (!this.payload.debitFromBalans) {
          return resultPrice;
        }

        return this.user.availableBalance > resultPrice ? 0
          : resultPrice - this.user.availableBalance;
      }

      return 0;
    },
    formValid() {
      if (!this.integrationId) {
        return false;
      }

      if (this.payFor === 'invoice' && this.payload.invoiceId) {
        const invoice = this.invoices.find((i) => i.id === this.payload.invoiceId);
        if (!invoice) {
          return false;
        }

        if (this.partialPaymentForInvoice && this.payload.summa) {
          return this.payload.summa <= invoice.needToPay && this.payload.summa > 0;
        }

        return true;
      }

      if (this.payFor === 'join' && this.payload.joinId) {
        const join = this.joins.find((j) => j.id === this.payload.joinId);
        if (!join) {
          return false;
        }

        const cl = this.classes.find((c) => c.id === join.classId);
        if (!cl || cl.payType === 'full') {
          return true;
        }

        let isSumValid = true;

        if (this.payload.summa) {
          isSumValid = this.payload.summa >= this.subPriceLimits.min
            && this.payload.summa <= this.subPriceLimits.max;
        }

        const subscriptionTypeIds = this.getSubscriptionTypesForClass(cl.id).map((s) => s.id);

        const isSubStartDate = this.isShowSubscriptionBeginDateSelect
          ? !!this.payload.subscriptionStartDate : true;

        return isSumValid
          && subscriptionTypeIds.includes(this.payload.subscriptionId) && isSubStartDate;
      }

      if (this.payFor === 'balans') {
        return this.payload.summa > 0;
      }

      return false;
    },
    createPaymentBtnText() {
      if (this.formValid && this.payload.debitFromBalans && this.totalSum === 0) {
        return 'Списать средства с баланса';
      }

      if (this.formValid && this.payload.debitFromBalans && this.totalSum > 0) {
        return 'Списать и перейти к оплате';
      }

      return 'Перейти к оплате';
    },
    onlinePaymentIntegrationsSelect() {
      let classId;
      let integrations;

      if (this.payFor === 'invoice' && this.payload.invoiceId) {
        const invoice = this.invoices.find((i) => i.id === this.payload.invoiceId);
        if (!invoice) {
          return null;
        }

        if (invoice.userSubscriptionId) {
          const sub = this.userSubscriptions.find((us) => us.id === invoice.userSubscriptionId);
          if (!sub) {
            return null;
          }

          classId = sub.mainClassId;
        }
        if (invoice.joinId) {
          const join = this.joins.find((j) => j.id === invoice.joinId);
          if (!join) {
            return null;
          }

          ({ classId } = join);
        }
      }

      if (this.payFor === 'join' && this.payload.joinId) {
        const join = this.joins.find((j) => j.id === this.payload.joinId);
        if (!join) {
          return null;
        }

        ({ classId } = join);
      }

      if (this.payFor !== 'balans' && !classId) {
        return null;
      }

      const sortFunc = (a, b) => (a.name > b.name ? 1 : -1);

      if (this.payFor !== 'balans') {
        const cl = this.classes.find((c) => c.id === classId);
        if (!cl) {
          return null;
        }

        integrations = this.onlinePaymentIntegrations
          .filter(({ filialIds }) => filialIds.includes(0) || filialIds.includes(cl.filialId))
          .sort(sortFunc);
      } else {
        integrations = this.onlinePaymentIntegrations
          .filter(({ filialIds }) => filialIds.includes(0)
            || filialIds.filter((value) => this.user.filials.includes(value)).length)
          .sort(sortFunc);
      }

      if (!integrations) {
        return null;
      }

      if (this.preassignedPayload.integrationId) {
        const int = integrations.find((i) => +this.preassignedPayload.integrationId === i.id);
        if (int) {
          return [int];
        }
      }

      return integrations;
    },
    ...mapGetters(['currency', 'user']),
  },
  methods: {
    updateRefreshKey() {
      // Ключ сделал для корректного обновления кнопки:
      // На Айфоне 6 при изменении некоторых параметров кнопка не становится активной -
      // проблема в перерисовке. key решает проблему.
      this.refreshKey += 1;
    },
    async createPayment() {
      this.loading = true;

      const paymentData = {
        userId: this.user.id,
        integrationId: this.integrationId,
      };

      if (this.payFor === 'invoice') {
        paymentData.payload = {
          payFor: 'invoice',
          invoiceId: this.payload.invoiceId,
          debitFromBalans: !!this.payload.debitFromBalans,
          summa: this.payload.summa ? +this.payload.summa : undefined,
        };
      }

      if (this.payFor === 'balans') {
        paymentData.payload = {
          payFor: 'balans',
          summa: +this.payload.summa,
        };
      }

      if (this.payFor === 'join') {
        const join = this.joins.find((j) => j.id === this.payload.joinId);
        if (!join) {
          return;
        }
        const subTypes = this.getSubscriptionTypesForClass(join.classId);
        paymentData.payload = {
          summa: this.payload.summa ? +this.payload.summa : undefined,
          joinId: this.payload.joinId,
          subscriptionId: this.payload.subscriptionId,
          subscriptionStartDate: this.payload.subscriptionStartDate,
          debitFromBalans: !!this.payload.debitFromBalans,
          payFor: subTypes.length ? 'subForExistingJoin' : 'existingJoin',
        };
      }

      try {
        const { data } = await Payment.create(paymentData, this.payLinkKey);
        if (data.result && data.directUrl) {
          window.location.href = data.directUrl;
        } else if (data.error && data.error.message) {
          // Если списали с баланса и платить не нужно
          if (data.error.code === 'noNeedToPay') {
            await this.$router.push({
              name: 'paymentResult',
              params: { payLinkKey: this.payLinkKey, result: 'success' },
            });
          } else {
            this.setSnackInfoMessage(data.error.message);
          }
        } else {
          this.setSnackErrorMessage('Не удалось создать оплату. Пожалуйста, обновите страницу');
          this.loading = false;
        }
      } catch (e) {
        if (e.response && e.response.data
          && e.response.data.error && e.response.data.error.message) {
          this.setSnackErrorMessage(e.response.data.error.message);
        } else {
          this.setSnackErrorMessage('Оплата невозможна. Пожалуйста, обновите страницу');
        }
        this.loading = false;
      }
    },
    getClassName(id) {
      const cl = this.classes.find((c) => c.id === id);
      if (!cl) {
        return null;
      }
      return cl.name;
    },
    getSubscriptionTypesForClass(classId) {
      const cl = this.classes.find((c) => c.id === classId);
      if (!cl || cl.payType === 'full') {
        return [];
      }

      const { subscriptionIds } = cl.onlinePayment;

      const enabledSubs = this.subscriptionTypes.filter((sub) => {
        const byFilial = sub.filialIds.includes(cl.filialId) || sub.filialIds.includes(0);
        // Если мультиабонемент, то не сортируем по курсам
        if ((sub.courses && sub.courses.length) || (sub.classes && sub.classes.length)) {
          return byFilial && ((sub.courses.indexOf(-1) !== -1
            || sub.courses.indexOf(cl.courseId) !== -1
            || sub.classes.indexOf(cl.id) !== -1));
        }

        const { courseIds, classIds } = sub.onlinePayment;

        return byFilial && (courseIds.includes(-1) || courseIds.indexOf(cl.courseId) !== -1
          || classIds.indexOf(cl.id) !== -1);
      });
      return enabledSubs
        .filter((sub) => subscriptionIds.includes(sub.id) || subscriptionIds.includes(-1))
        .map((sub) => {
          sub.displayName = `${sub.name} - ${sub.price} ${this.currency}, ${sub.periodDisplay}, ${sub.visitCountDisplay}`;

          return sub;
        })
        .sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1));
    },
    changePayFor() {
      this.payload = {};

      if (this.payFor === 'join') {
        this.payload.subscriptionStartDate = 'fromNow';
      }

      this.setDefaultIntegration();
      this.updateRefreshKey();
    },
    joinChange() {
      this.setDefaultIntegration();
      this.setDefaultSubscription();
      this.updateRefreshKey();
    },
    subsChange() {
      this.updateRefreshKey();
    },
    invoiceChange() {
      this.setDefaultIntegration();
      this.updateRefreshKey();
    },
    parseUrlParams() {
      const urlParams = this.$router.currentRoute.query;

      const needlePayloadKeys = ['integrationId', 'invoiceId', 'joinId', 'subscriptionId', 'payFor', 'summa', 'subscriptionStartDate'];
      needlePayloadKeys.forEach((key) => {
        const value = Number(urlParams[key]) || urlParams[key];
        if (value) {
          this.preassignedPayload[key] = value;
          this.payload[key] = value;
        }
      });

      this.payFor = urlParams.payFor || this.payFor;
    },
    validatePayload() {
      if (!['invoice', 'join', 'balans'].includes(this.payFor)) {
        this.payFor = 'invoice';
      }

      const {
        joinId,
        summa,
        invoiceId,
        integrationId,
        subscriptionId,
        subscriptionStartDate,
      } = this.payload;

      if (invoiceId && !this.selectInvoices.find(({ id }) => id === invoiceId)) {
        delete this.payload.invoiceId;
        delete this.preassignedPayload.invoiceId;
        delete this.preassignedPayload.payFor;
      }

      if (joinId && !this.selectJoins.find(({ id }) => id === joinId)) {
        delete this.payload.joinId;
        delete this.preassignedPayload.joinId;
        delete this.preassignedPayload.payFor;
      }

      if (subscriptionId && !this.selectSubscriptionTypes.find(({ id }) => id === subscriptionId)) {
        delete this.payload.subscriptionId;
        delete this.preassignedPayload.subscriptionId;
      }

      if (integrationId
        && this.onlinePaymentIntegrationsSelect
        && !this.onlinePaymentIntegrationsSelect.find(({ id }) => id === integrationId)) {
        this.integrationId = null;
        delete this.preassignedPayload.integrationId;
      }

      const { min, max } = this.subPriceLimits;
      if (summa && (summa < min || summa > max || (!max && !min))) {
        delete this.payload.summa;
        delete this.preassignedPayload.summa;
      }

      if (subscriptionStartDate && !this.payload.subscriptionId) {
        delete this.payload.subscriptionStartDate;
        delete this.preassignedPayload.subscriptionStartDate;
      }
    },
    async loadUserData() {
      this.isLoadingUserData = true;
      this.payLinkKey = this.$router.currentRoute.params.payLinkKey;

      try {
        const { data } = await UserData.fetch({ key: this.payLinkKey });

        this.joins = data.joins;
        this.classes = data.classes;
        this.invoices = data.invoices;
        this.uiSettings = data.uiSettings;
        this.totalDebt = data.invoicesStats.totalDebt;
        this.userSubscriptions = data.userSubscriptions;
        this.subscriptionTypes = data.subscriptionTypes;
        this.currentDebt = data.invoicesStats.currentDebt;
        this.onlinePaymentIntegrations = data.onlinePaymentIntegrations;

        this.setUserData(data);

        this.parseUrlParams();
        this.validatePayload();

        this.isLoadingUserData = false;
      } catch (e) {
        if (e.response && e.response.status === 403) {
          await this.$router.push({ name: 'notCorrectPayLinkKey' });
        } else {
          this.setSnackErrorMessage('Произошла ошибка, попробуйте позже');
          throw e;
        }
      }
    },
    setDefaultSubscription() {
      if (this.selectSubscriptionTypes && this.selectSubscriptionTypes.length === 1) {
        this.payload.subscriptionId = this.selectSubscriptionTypes[0].id;
        this.subsChange();
      } else {
        delete this.payload.subscriptionId;
      }
    },
    setDefaultIntegration() {
      if (!this.onlinePaymentIntegrationsSelect) {
        return;
      }

      if (this.onlinePaymentIntegrationsSelect.length === 1) {
        this.integrationId = this.onlinePaymentIntegrationsSelect[0].id;
      }

      const { integrationId } = this.preassignedPayload;
      if (integrationId
        && this.onlinePaymentIntegrationsSelect.find(({ id }) => id === integrationId)) {
        this.integrationId = integrationId;
      }
    },
    ...mapMutations(['setSnackInfoMessage', 'setSnackErrorMessage', 'setUserData']),
  },
  async mounted() {
    await this.loadUserData();
    this.setDefaultIntegration();
    this.setDefaultSubscription();
  },
};
</script>

<style scoped>
.logo {
  height: 120px;
}
</style>
