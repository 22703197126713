import { render, staticRenderFns } from "./MainFooter.vue?vue&type=template&id=49cc718d&scoped=true&"
import script from "./MainFooter.vue?vue&type=script&lang=js&"
export * from "./MainFooter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49cc718d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFooter } from 'vuetify/lib/components/VFooter';
installComponents(component, {VFooter})
