<template>
  <v-app>
    <main-toolbar></main-toolbar>
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-snackbar v-model="snackInfo" top right color="info">
      {{ snackInfoMessage }}
    </v-snackbar>
    <v-snackbar v-model="snackError" top right color="error">
      {{ snackErrorMessage }}
    </v-snackbar>
    <main-footer></main-footer>
  </v-app>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import MainToolbar from '@/components/MainToolbar.vue';
import MainFooter from '@/components/MainFooter.vue';

export default {
  name: 'App',

  components: {
    MainToolbar,
    MainFooter,
  },

  data: () => ({}),
  computed: {
    snackInfo: {
      get() {
        return !!this.snackInfoMessage;
      },
      set() {
        this.setSnackInfoMessage(null);
      },
    },
    snackError: {
      get() {
        return !!this.snackErrorMessage;
      },
      set() {
        this.setSnackErrorMessage(null);
      },
    },
    ...mapGetters(['snackInfoMessage', 'snackErrorMessage']),
  },
  methods: {
    ...mapMutations(['setSnackInfoMessage', 'setSnackErrorMessage']),
  },
};
</script>
