<template>
  <v-app-bar app class="primary">
    <v-toolbar-title class="text--contrast">Оплата</v-toolbar-title>
    <v-spacer></v-spacer>
    <div v-if="user" class="text--contrast body-2">
      <div>Ваш ID: {{ user.id }}</div>
      <div>Баланс: {{ user.availableBalance }} {{ currency }}</div>
    </div>
  </v-app-bar>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'MainToolbar',
  computed: {
    ...mapGetters(['currency', 'user']),
  },
};
</script>

<style scoped>

</style>
