<template>
  <div>
    <v-alert v-if="result === 'success'" outlined width="500" type="success" class="ma-auto">
      <div>Ваш платеж успешно выполнен.</div>
      <a @click="payAgain()">Оплатить еще</a>
    </v-alert>
    <v-alert v-if="result === 'fail'" outlined width="500" type="error" class="ma-auto">
      <div>Не удалось выполнить платеж.</div>
      <a @click="payAgain()">Попробовать снова</a>
    </v-alert>
  </div>
</template>

<script>
import UserData from '@/api/UserData';
import { mapMutations } from 'vuex';

export default {
  name: 'PaymentResult',
  components: {},
  data: () => ({}),
  computed: {
    result() {
      return this.$router.currentRoute.params.result;
    },
  },
  methods: {
    async payAgain() {
      const { payLinkKey } = this.$router.currentRoute.params;
      await this.$router.push({ name: 'createPayment', params: { payLinkKey } });
    },
    async loadUserData() {
      this.payLinkKey = this.$router.currentRoute.params.payLinkKey;
      try {
        const { data } = await UserData.fetch({ key: this.payLinkKey });
        this.setUserData(data);
      } catch (e) {
        if (e.response && e.response.status === 403) {
          await this.$router.push({ name: 'notCorrectPayLinkKey' });
        } else {
          this.setSnackErrorMessage('Произошла ошибка, попробуйте позже');
          throw e;
        }
      }
    },
    ...mapMutations(['setSnackErrorMessage', 'setUserData']),
  },
  async mounted() {
    await this.loadUserData();
  },
};
</script>

<style scoped>

</style>
