import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';
import ru from 'vuetify/src/locale/ru.ts';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    locales: { ru },
    current: 'ru',
  },
  theme: {
    themes: {
      light: {
        primary: colors.green.lighten1,
        secondary: colors.green.lighten4,
        accent: colors.red.accent2,
      },
    },
  },
});
