import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    snackInfoMessage: null,
    snackErrorMessage: null,
    user: null,
    currency: null,
  },
  mutations: {
    setSnackInfoMessage: (state, value) => {
      state.snackInfoMessage = value;
    },
    setSnackErrorMessage: (state, value) => {
      state.snackErrorMessage = value;
    },
    setUserData: (state, { user, company }) => {
      state.user = user;
      state.currency = company.currency;
    },
  },
  getters: {
    user: (state) => state.user,
    currency: (state) => state.currency,
    snackInfoMessage: (state) => state.snackInfoMessage,
    snackErrorMessage: (state) => state.snackErrorMessage,
  },
  actions: {
  },
  modules: {
  },
});
