<template>
  <v-footer class="d-flex justify-space-between">
    <div class="mt-2 text-center">
      <img src="../assets/payment_systems.png" alt="Платежные системы" width="150">
    </div>
    <div class="caption grey--text">
      Работает на <a href="https://moyklass.com" target="_blank">"Мой Класс"</a>
    </div>
  </v-footer>
</template>

<script>

export default {
  name: 'MainFooter',
};
</script>

<style scoped>

</style>
